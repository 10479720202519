<template>
  <router-link :to="'/consulta/' + appointment.id ">
    <b-card no-body>
      <b-card-body class="d-flex justify-content-start align-items-center">
        <b-avatar
          size="70px"
          variant="light-primary"
          :text="getAbreviateByPatient(appointment.patient)"
        />
        <div class="truncate ml-2">
          <h2 class="mb-25 font-weight-bolder">
            {{ appointment.patient.physicalPerson.firstName }}
          </h2>
          <span class="mb-25 font-weight-bolder">
            {{ appointment.patient.physicalPerson.lastName }}
          </span>
          <div>
            <span class="small">
              {{ getFromNowByDate(appointment.startDate) }}
            </span>
          </div>

          <!-- <span>{{ statisticTitle }}</span> -->
        </div>
      </b-card-body>
    </b-card>
  </router-link>
</template>

<script>
import { BCard, BCardBody, BAvatar } from 'bootstrap-vue'
import utilsService from '@/services/utilsService'

export default {
  components: {
    BCardBody,
    BAvatar,
    BCard,
  },
  props: {
    appointment: {
      type: Object,
      required: true,
    },
  },
  methods: {
    ...utilsService,
  },
}
</script>
